import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TrainForm from './newItem';

import styles from '../../styles/form.module.scss';

class EditPartTrainForm extends Component {
  static propTypes = {
    train: PropTypes.object.isRequired,
    employees: PropTypes.array,
    tripService: PropTypes.object,
    travelPolicyService: PropTypes.object.isRequired,
    projects: PropTypes.array,
    onConfirm: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    guidService: PropTypes.object.isRequired,
    mandatoryProject: PropTypes.array,
  };

  static defaultProps = {
    tripService: [],
    employees: [],
    projects: [],
    analytics: [],
    tripAnalyticsValueIds: [],
    setTripAnalytics: () => {},
    mandatoryProject: [],
  };

  constructor(props) {
    super(props);
    const { validation, Description } = props.tripService.getTrainData();
    this.state = {
      validation,
      Description,
    };
  }

  componentDidMount() {
    const { tripService } = this.props;
    this.unsubscribeTrainData = tripService.subscribeTrain(this.updateTrainData);
  }

  componentWillUnmount() {
    this.unsubscribeTrainData();
  }

  updateTrainData = ({ Description, validation }) => {
    this.setState({
      validation,
      Description,
    });
  };

  handleChangeInput = (value, path) => {
    this.props.tripService.changeField(value, path);
  }

  render() {
    const { train, employees, tripService, travelPolicyService, projects, onConfirm, accountId, analytics, tripAnalyticsValueIds, setTripAnalytics, guidService, mandatoryProject } = this.props;
    const preparedTrain = { ...train, Employee: train.Employees[0] };

    return (
      <div className={ `${styles.row} ${styles.panel}` }>
        <h4>Описание изменения</h4>
        <TrainForm
          isEdit
          train={ preparedTrain }
          accountId={ accountId }
          onConfirm={ onConfirm }
          tripService={ tripService }
          travelPolicyService={ travelPolicyService }
          projects={ projects }
          employees={ employees }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          guidService={ guidService }
          partEditItem
          mandatoryProject={ mandatoryProject }
        />
      </div>
    );
  }
}

export default EditPartTrainForm;
