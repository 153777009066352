import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadForm from '../../../../components/UploadForm';

import style from '../../styles/form.module.scss';
import NoResult from '../../../../components/NoResult';
import { REQUIRED_EXTENSIONS, UPLOAD_FORM } from '../../../../bi/constants/trips';

class UploadFile extends Component {
  static propTypes = {
    tripService: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    const { validation, file } = props.tripService.getTrainData();
    this.state = {
      validation,
      file,
    };
  }

  componentDidMount() {
    const { tripService } = this.props;
    this.unsubscribeTrainData = tripService.subscribeTrain(this.updateTrainData);
  }

  componentWillUnmount() {
    this.unsubscribeTrainData();
  }

  updateTrainData = ({ file, validation }) => {
    this.setState({
      validation,
      file,
    });
  };

  handleFileAdded = async (file) => {
    await this.props.tripService.uploadFile(file);
  }

  handleOnRemove = () => {
    this.props.tripService.resetUploadedFile();
  }

  resetError = () => this.props.tripService.resetError();

  renderErrorDialog = () => {
    const { file: { failed, error } } = this.state;

    return failed && (
      <NoResult
        onClose={ this.resetError }
        message={ error }
      />
    );
  };

  render() {
    const { file: { uploaded, File }, validation: { guid } } = this.state;

    const messageHtml = !uploaded &&
      <span className={ style['error-msg'] }>{ guid }</span>;

    return (
      <div className={ `${style.row} ${style.wrap_upload}` }>
        <div className={ style['col-1-2'] }>
          <UploadForm
            extensions={ REQUIRED_EXTENSIONS }
            onFileAdded={ this.handleFileAdded }
            onFileRemoved={ this.handleOnRemove }
            chooseFileLabel={ UPLOAD_FORM.CHOOSE_FILE }
            invalidExtension={ UPLOAD_FORM.INVALID_EXTENSION }
            description={ UPLOAD_FORM.DESCRIPTION }
            uploaded={ uploaded }
            file={ File }
          />
          { messageHtml }
        </div>
        { this.renderErrorDialog() }
      </div>
    );
  }
}

export default UploadFile;
