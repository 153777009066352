import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import { getMoment, formatDate } from '../../../../bi/utils/formatDate';
import MoneyFormat from '../../../../bi/utils/money';
import { FlatButton } from '../../../../components/button';
import CircularLoaders from '../../../../components/loaders';

import { TRAIN_TRANSIT_DOCUMENT_TYPE, TRAIN_TRANSIT_DOCUMENT_LABEL } from '../../../../bi/constants/train';

import styles from '../../styles/form.module.scss';
import { FORMATDATETIME } from '../../../../constants/time';

const NOSPECIFIED = 'не указан';

class Train extends Component {
  static propTypes = {
    train: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();

    const { EventDate, JsonData, Price, Employees } = props.train;

    const voucher = JSON.parse(JsonData);
    const eventDate = getMoment(EventDate);

    const {
      DateArrive,
      DateDeparture,
      OrderId,
      StationArrive,
      StationDepart,
      TicketNumber,
      TrainNumber,
      TrainPricing,
      PriceDetails,
      TransitDocumentType,
    } = voucher;

    const employees = Employees.map((employee) => {
      const passport = employee.Documents.find(document => document.Id === voucher.PassportId);

      return `${employee.Surname} ${employee.Name} ${employee.Patronymic ? employee.Patronymic : ''} - паспорт ${passport ? passport.Number : NOSPECIFIED}`;
    });

    this.state = {
      from: StationDepart,
      to: StationArrive,
      number: TrainNumber,
      date: formatDate(eventDate),
      checkin: formatDate(DateDeparture, FORMATDATETIME),
      checkout: formatDate(DateArrive, FORMATDATETIME),
      price: MoneyFormat.moneyWithDecimal(Price),
      trainPricing: TrainPricing,
      priceDetails: PriceDetails,
      orderId: OrderId,
      ticketNumber: TicketNumber,
      showCancellationFee: false,
      loading: false,
      cancellationFee: null,
      employees,
      transitDocument: TransitDocumentType,
    };
  }

  handlerOpenDialog = (item) => {
    this.setState({
      loading: true,
    });

    this.props.tripService.getCancellationFee(item.CompanyId, item.TripItemId).then((res) => {
      this.setState({
        showCancellationFee: true,
        loading: false,
        cancellationFee: res,
      });
    });
  };

  handlerCloseDialog = () => {
    this.setState({
      showCancellationFee: false,
    });
  };

  renderDialog = () => {
    const { cancellationFee } = this.state;

    let dialogHtml = null;
    if (cancellationFee.Result) {
      dialogHtml = (
        <Dialog onClick={ this.handlerCloseDialog }>
          <div className={ styles['dialog-cancellationFee'] }>
            { cancellationFee.PenaltySum }
          </div>
        </Dialog>
      );
    } else {
      // eslint-disable-next-line no-alert
      alert(cancellationFee.Error || 'Не удалось получить стоимость отмены');
    }

    return dialogHtml;
  };

  renderTransitDocument = () => {
    const { transitDocument } = this.state;

    if (!transitDocument) return null;

    const isPermission = transitDocument === TRAIN_TRANSIT_DOCUMENT_TYPE.PERMISSION;
    const titleTransitDocument = isPermission
      ? TRAIN_TRANSIT_DOCUMENT_LABEL.PERMISSION
      : TRAIN_TRANSIT_DOCUMENT_LABEL.UPD_TITLE;

    return (
      <div className={ styles.row }>
        { titleTransitDocument }
      </div>
    );
  }

  render() {
    const {
      from,
      to,
      number,
      date,
      checkin,
      checkout,
      price,
      employees,
      trainPricing,
      priceDetails,
      orderId,
      ticketNumber,
      loading,
      showCancellationFee,
    } = this.state;

    const labelButton = loading ? (<CircularLoaders wrapWidth={ 24 } />) : 'Стоимость отмены';
    const dialogCancellationFeeHtml = showCancellationFee && !loading ? this.renderDialog() : null;

    return (
      <div className={ styles.wrap }>
        <div className={ styles.row }>
          <div className={ styles.first }>
            Номер билета: { ticketNumber }
          </div>
          <div>
            Номер заказа: { orderId }
          </div>
        </div>
        <div className={ styles.row }>
          Дата заказа: { date }
        </div>
        <div className={ `${styles.row} ${styles.title}` }>
          <div className={ styles.first }>
            Поезд № { number }
          </div>
          <div >
            Отправление: { from }, { checkin }
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.first }>
            Цена: { price }
          </div>
          <div>
            Прибытие: { to }, { checkout }
          </div>
        </div>
        <div className={ `${styles.row} ${styles.employee}` }>
          { employees.map((employee, ind) => <div key={ ind }>{ employee }</div>) }
        </div>
        { this.renderTransitDocument() }
        <div className={ styles.row }>
          <div className={ styles['col-1-4'] }>
            <div className={ styles['title-price'] }>TrainPricing</div>
            <div className={ `${styles.row} ${styles['row-price']}` }>
              <span>Cost: { trainPricing.Cost }</span>
              <span>TariffPrice: { trainPricing.TariffPrice }</span>
              <span>Fare: { trainPricing.Fare }</span>
              <span>Tva: { trainPricing.Tva }</span>
              <span>ServiceCost: { trainPricing.ServiceCost }</span>
              <span>ServiceTva: { trainPricing.ServiceTva }</span>
              <span>TvaRate: { trainPricing.TvaRate }</span>
              <span>ServiceTvaRate: { trainPricing.ServiceTvaRate }</span>
              <span>RefundTva: { trainPricing.RefundTva }</span>
              <span>RefundTvaRate: { trainPricing.RefundTvaRate }</span>
            </div>
          </div>
          <div className={ styles['col-1-4'] }>
            <div className={ styles['title-price'] }>PriceDetails</div>
            <div className={ `${styles.row} ${styles['row-price']}` }>
              <span>Base: { priceDetails.Base }</span>
              <span>Fee: { priceDetails.Fee }</span>
              <span>Tax: { priceDetails.Tax }</span>
              <span>Taxes: { priceDetails.Taxes }</span>
              <span>HasVAT: { priceDetails.HasVAT }</span>
              <span>VAT: { priceDetails.VAT }</span>
              <span>Commission: { priceDetails.Commission }</span>
              <span>Total: { priceDetails.Total }</span>
            </div>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['train-button'] }>
            <FlatButton onClick={ () => this.handlerOpenDialog(this.props.train) }>
              { labelButton }
            </FlatButton>
          </div>
        </div>
        { dialogCancellationFeeHtml }
      </div>
    );
  }
}

export default Train;
