import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'sw-ui';

import { TRAIN_PROVDERS } from '../../../../bi/constants/train';
import COMPONENTS from '../../../../bi/constants/components';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import AjaxButton from '../../../../components/ajaxButton';
import Input from '../../../../components/input';

import styles from '../../styles/trip.module.scss';
import styl from '../../styles/form.module.scss';

const LABELS = {
  PROVIDER: 'Провайдер',
  ORDER: 'Номер позиции в заказе',
  SALE_ORDER: 'Номер заказа',
  GET_INFO: 'Добавить',
};

const FIELDS = {
  ORDER: 'Order',
  SALE_ORDER: 'SaleOrder',
};

const PROVIDER_VALUES = [
  { value: TRAIN_PROVDERS.TeleTrain, label: 'TeleTrain' },
  { value: TRAIN_PROVDERS.Oneliya, label: 'Oneliya' },
];

class SelectKnownOrder extends Component {
  static propTypes = {
    onInfoFetched: PropTypes.func.isRequired,
    tripService: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      orderId: '',
      saleOrderId: '',
      provider: TRAIN_PROVDERS.TeleTrain,
      waitingResponse: false,
    };
  }

  handleChangeOrderId = event => this.setState({ orderId: event.target.value });

  handleChangeSaleOrderId = event => this.setState({ saleOrderId: event.target.value });

  handleChangeProvder = ({ value }) => this.setState({ provider: value });

  getInfo = (event) => {
    event.preventDefault();

    const { orderId, provider, saleOrderId } = this.state;
    const { tripService, onInfoFetched, accountId } = this.props;
    let newItem = {};

    this.setState({
      waitingResponse: true,
    }, async () => {
      try {
        newItem = await tripService.getOrderTrainInfo(accountId, orderId, saleOrderId, provider);
      } catch (err) {
        newItem = tripService.addNewItem(SERVICETYPE.TRAIN);
      }

      this.setState({ waitingResponse: false }, () => onInfoFetched(newItem));
    });
  };

  stylesCol = () => this.state.provider === TRAIN_PROVDERS.Oneliya ?
    styles['col-1-4'] :
    styles['col-1-3'];

  renderOrderIdInput() {
    const { orderId } = this.state;

    return (
      <div className={ this.stylesCol() }>
        <Input
          field={ FIELDS.ORDER }
          value={ orderId }
          label={ LABELS.ORDER }
          onChange={ this.handleChangeOrderId }
        />
      </div>
    );
  }

  renderSaleOrderIdInput() {
    const { saleOrderId, provider } = this.state;

    if (provider === TRAIN_PROVDERS.TeleTrain) return null;

    return (
      <div className={ this.stylesCol() }>
        <Input
          field={ FIELDS.ORDER }
          value={ saleOrderId }
          label={ LABELS.SALE_ORDER }
          onChange={ this.handleChangeSaleOrderId }
        />
      </div>
    );
  }

  renderProvderSelect() {
    const { provider } = this.state;

    return (
      <div className={ this.stylesCol() }>
        <Select
          items={ PROVIDER_VALUES }
          value={ provider }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ this.handleChangeProvder }
        />
      </div>
    );
  }

  renderGetInfoButton() {
    const { waitingResponse } = this.state;

    return (
      <div className={ styl['add-provider'] }>
        <AjaxButton
          label={ LABELS.GET_INFO }
          loading={ waitingResponse }
          onClick={ this.getInfo }
        />
      </div>
    );
  }

  render() {
    return (
      <div className={ styles.row }>
        <div className={ `${styles.panel} ${styles['col-1-2']}` }>
          <form className={ `${styles.row} ${styles.provider}` }>
            { this.renderProvderSelect() }
            { this.renderSaleOrderIdInput() }
            { this.renderOrderIdInput() }
            { this.renderGetInfoButton() }
          </form>
        </div>
      </div>
    );
  }
}

export default SelectKnownOrder;
