import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../components/input';
import Suggest from '../../../../components/Suggest';

import { isInputInvalid, changeCommaToThePoint, getSumPrices, calculationOfVAT } from '../../../../bi/utils/trip';
import { LIMITS_INTPUT, TRAIN_PRICING, FIELDS, PRICE_DETAILS, TARIFF_TEXT, CARRIER_DETAILS } from '../../../../bi/constants/trips';
import lodashReplaces from '../../../../bi/utils/lodashReplaces';
import { noNumbers, numbersAndPoint } from '../../../../bi/constants/regExp';

import styles from '../../styles/form.module.scss';

const NO_CARRIERS = 'Нет перевозчиков';

class Tariff extends Component {
  static propTypes = {
    tripService: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    const { validation, JsonData, carriers } = props.tripService.getTrainData();
    this.state = {
      validation,
      JsonData,
      carriers,
    };
  }

  componentDidMount() {
    const { tripService } = this.props;
    tripService.getTrainCarriers();
    this.unsubscribeTrainData = tripService.subscribeTrain(this.updateTrainData);
  }

  componentWillUnmount() {
    this.unsubscribeTrainData();
  }

  updateTrainData = ({ carriers, validation, JsonData }) => {
    this.setState({
      validation,
      carriers,
      JsonData,
    });
  };

  handleChangeTrainPricingInput = async (e, path, limit) => {
    const { target: { value } } = e;
    const { tripService: { changeField, changeFields } } = this.props;

    const isSymbolInvalid = !numbersAndPoint.test(value);

    if (isInputInvalid(value, isSymbolInvalid, limit)) {
      e.preventDefault();
      return;
    }

    await changeField(value, `${FIELDS.JSON_DATA}.${path}`);

    const paths = getSumPrices(this.state.JsonData);

    await changeFields(paths);
  }

  handleBlurTrainPricingInput = (value, path) => {
    const { tripService } = this.props;

    const isValid = tripService.trainTripFieldValidation(path, value);
    tripService.changeField(isValid, `${FIELDS.VALIDATION}.${path}`);
  }

  handleChangeInput = (e, path, limit) => {
    const { target: { value } } = e;
    const { tripService } = this.props;
    const isSymbolInvalid = path === PRICE_DETAILS.COMMISSION ? noNumbers.test(value) : !numbersAndPoint.test(value);

    if (isInputInvalid(value, isSymbolInvalid, limit)) {
      e.preventDefault();
      return;
    }

    const isValid = tripService.trainTripFieldValidation(path, value);
    tripService.changeField(isValid, `${FIELDS.VALIDATION}.${path}`);

    tripService.changeField(changeCommaToThePoint(value), `${FIELDS.JSON_DATA}.${path}`);
  };

  handleBlurInput = (value, path) => {
    const { tripService } = this.props;

    const isValid = tripService.trainTripFieldValidation(path, value);
    tripService.changeField(isValid, `${FIELDS.VALIDATION}.${path}`);
  }

  handleSelect = (value, path) => {
    const { tripService } = this.props;
    const { name, Inn, Name } = value;
    const isValid = tripService.trainTripFieldValidation(path, name);

    const paths = [
      { value: name, path: `${FIELDS.JSON_DATA}.${path}` },
      { value: Name || '', path: `${FIELDS.JSON_DATA}.${CARRIER_DETAILS.CARRIER}` },
      { value: Inn || '', path: `${FIELDS.JSON_DATA}.${CARRIER_DETAILS.INN}` },
      { value: isValid, path: `${FIELDS.VALIDATION}.${path}` },
    ];

    tripService.changeFields(paths);
  }

  handleChange = (
    event,
    fieldData,
    label,
    limit,
    path
  ) => {
    if (path === TRAIN_PRICING.SERVICE_TVA_RATE || path === CARRIER_DETAILS.INN) {
      return () => {};
    }

    return this.handleChangeInput(event, fieldData, limit);
  };

  renderTrainPricingInput = (label, path, limit) => {
    const { validation, JsonData } = this.state;

    const valueData = lodashReplaces.getValueInObjByPath(JsonData, path);
    const valid = lodashReplaces.getValueInObjByPath(validation, path);

    const onChange = (event, fieldData) => {
      if (path === TRAIN_PRICING.COST) {
        return () => {};
      }

      return this.handleChangeTrainPricingInput(event, fieldData, limit);
    };

    return (
      <Input
        value={ valueData }
        field={ path }
        label={ label }
        onChange={ (e, field) => onChange(e, field) }
        onBlur={ ({ target: { value } }) => this.handleBlurTrainPricingInput(value, path) }
        valid={ valid }
      />
    );
  }

  renderInput = (label, path, limit) => {
    const { validation, JsonData } = this.state;

    const valueData = lodashReplaces.getValueInObjByPath(JsonData, path);
    const valid = lodashReplaces.getValueInObjByPath(validation, path);

    let includingVAT = '';

    if (label === TARIFF_TEXT.SMARTWAY_FEE) {
      const vat = calculationOfVAT(valueData);
      includingVAT = `${TARIFF_TEXT.INCLUDING_VAT} = ${vat}`;
    }

    const message = valueData ? includingVAT : null;

    return (
      <React.Fragment>
        <Input
          value={ valueData }
          field={ path }
          label={ label }
          onChange={ (e, field) => this.handleChange(e, field, label, limit, path) }
          onBlur={ ({ target: { value } }) => this.handleBlurInput(value, path) }
          valid={ valid }
        />
        <span>{ message }</span>
      </React.Fragment>
    );
  }

  renderSelect = (title, path) => {
    const { validation, carriers, JsonData } = this.state;

    const inValid = lodashReplaces.getValueInObjByPath(validation, path);
    const currentLabel = lodashReplaces.getValueInObjByPath(JsonData, path);

    if (!carriers.length) {
      return <div className={ styles['projects-absence'] }>{NO_CARRIERS}</div>;
    }

    return (
      <Suggest
        title={ title }
        valid={ inValid }
        suggests={ carriers }
        currentLabel={ currentLabel }
        onSelect={ value => this.handleSelect(value, path) }
        withScroll
      />
    );
  };

  renderCarriers = () => (
    <div className={ styles.row }>
      <div className={ styles['col-1-3'] }>
        {this.renderSelect(TARIFF_TEXT.CARRIER, CARRIER_DETAILS.FULL_NAME)}
      </div>
      <div className={ styles['col-1-4'] }>
        {this.renderInput(TARIFF_TEXT.CARRIER_INN, CARRIER_DETAILS.INN)}
      </div>
    </div>
    );

  render() {
    return (
      <div>
        <div className={ styles.row }>
          <div className={ `${styles.row} ${styles.title}` }>
            <h4>{TARIFF_TEXT.TITLE}</h4>
          </div>
        </div>
        <div className={ styles.wrap_price }>
          <div className={ styles['col-1-4'] }>
            {this.renderTrainPricingInput(TARIFF_TEXT.TARIFF_PRICE, TRAIN_PRICING.TARIFF_PRICE, LIMITS_INTPUT.TEN)}
          </div>
          <div className={ styles['col-1-4'] }>
            {this.renderTrainPricingInput(TARIFF_TEXT.FARE, TRAIN_PRICING.FARE, LIMITS_INTPUT.TEN)}
          </div>
          <div className={ styles['col-1-4'] }>
            {this.renderTrainPricingInput(TARIFF_TEXT.PRICE, TRAIN_PRICING.COST)}
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-4'] }>
            {this.renderInput(TARIFF_TEXT.VAT_BASE, TRAIN_PRICING.SERVICE_TVA_RATE)}
          </div>
          <div className={ styles['col-1-4'] }>
            {this.renderInput(TARIFF_TEXT.VAT_AMOUNT, TRAIN_PRICING.SERVICE_TVA, LIMITS_INTPUT.TEN)}
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-4'] }>
            {this.renderInput(TARIFF_TEXT.RZD_FEE, FIELDS.PROVIDER_COMMISSION, LIMITS_INTPUT.TEN)}
          </div>
          <div className={ styles['col-1-4'] }>
            {this.renderInput(TARIFF_TEXT.SMARTWAY_FEE, PRICE_DETAILS.COMMISSION, LIMITS_INTPUT.THREE)}
          </div>
        </div>
        {this.renderCarriers()}
      </div>
    );
  }
}

export default Tariff;
